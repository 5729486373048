
























































@import '@design';
@import '@styleMixins';

.member-img {
  width: 100%;
  max-width: 250px;
}

.member-name {
  @extend .freight-pro--font;

  @include font-size(44px, 0.7, 0.75, 0.85);

  line-height: 1;
  text-transform: uppercase;
}

.member-title {
  @extend .rubik--font;

  @include font-size(15px, 0.7, 0.75, 0.85);

  text-transform: uppercase;
}

.member-title-underline {
  width: 80%;
  max-width: 300px;
  height: 2px;
  margin: $size-grid-padding auto;
  background-color: $color-treps-blue;
}

.member-bio {
  width: 100%;
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
}
